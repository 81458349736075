var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.errorMessage)?_c('div'):_vm._e(),(_vm.booking && _vm.roles.BOOKER)?_c('BookingPaymentStatus',{attrs:{"booking":_vm.relevantBooking},on:{"onStatusChanged":_vm.handleChangedStatus}}):_vm._e(),(_vm.booking)?_c('div',{staticClass:"booking"},[(_vm.booking.space)?_c('section',{staticClass:"booking-info"},[_c('div',{staticClass:"booking-info-content"},[_c('div',{staticClass:"booking-info__details"},[_c('div',{staticClass:"booking-info__links"},[_c('router-link',{staticClass:"link-underline",attrs:{"target":"_blank","to":_vm.useGetLocalizedPath(
                  ("" + (_vm.VenueRoutes.VENUE) + (_vm.booking.space.venue.venue_url))
                )}},[_c('h1',[_vm._v(_vm._s(_vm.booking.space.venue.venue_name))])]),_c('span',{staticClass:"booking-info__links__divider"},[_vm._v("|")]),_c('router-link',{staticClass:"link-underline",attrs:{"target":"_blank","to":_vm.useGetLocalizedPath(
                  ("" + (_vm.SpaceRoutes.SPACE) + (_vm.booking.space.space_url))
                )}},[_c('h1',[_vm._v(_vm._s(_vm.booking.space.internal_name))])])],1),_c('p',[_vm._v(_vm._s(_vm.booking.space.venue.venue_geo.address))])]),_c('div',{staticClass:"booking-info__labels"},[_c('Label',{attrs:{"type":"outline"}},[_vm._v(" "+_vm._s(_vm.$t( ("booking.details.labels.status." + (_vm.relevantBooking.booking_type.toLowerCase())) ))+" "),_c('i',{class:("fa-solid fa-" + (_vm.bookingStatusImages[_vm.relevantBooking.booking_type]) + " fa-xs"),staticStyle:{"margin-left":"3px"}})]),(_vm.relevantBooking && _vm.relevantBooking.is_afterpay_requested)?_c('Label',{attrs:{"type":"outline"}},[_vm._v(" "+_vm._s(_vm.$t('booking.details.labels.is_afterpay_requested'))+" ")]):_vm._e()],1),_c('div',{staticClass:"booking-info__calendar"},[_c('p',{staticClass:"booking-info__calendar__title"},[_vm._v(" "+_vm._s(_vm.$t('email_engine.order_paid.add_to_calendar_text'))+" ")]),_c('a',{attrs:{"href":("https://www.google.com/calendar/render?action=TEMPLATE&sf=true&output=xml&text=" + (_vm.booking.meeting_name) + "&dates=" + (_vm.convertUnixToExternalCalendarDate(
              _vm.booking.slot_start
            )) + "/" + (_vm.convertUnixToExternalCalendarDate(
              _vm.booking.slot_end
            )) + "&details=" + (_vm.booking.meeting_description || '') + "&location=" + (_vm.booking.space.venue.venue_geo.address) + "&ctz=" + _vm.timezone + "&sprop=" + _vm.SITE_URL),"target":"_blank"}},[_c('img',{staticClass:"booking-info__calendar__image",attrs:{"src":"https://media.wemeet.nl/gallery/assets/email/icons8-google-calendar-48_r_qkzHWyj.png","alt":"Google Calendar","title":"Google Calendar"}})]),_c('a',{attrs:{"href":("https://www.icloud.com/calendar/?action=template&title=" + (_vm.booking.meeting_name) + "&location=" + (_vm.booking.space.venue.venue_geo.address) + "&desc=" + (_vm.booking.meeting_description || '') + "&date=" + (_vm.convertUnixToExternalCalendarDate(
              _vm.booking.slot_start
            )) + "/" + (_vm.convertUnixToExternalCalendarDate(_vm.booking.slot_end))),"target":"_blank"}},[_c('img',{staticClass:"booking-info__calendar__image",attrs:{"src":"https://media.wemeet.nl/gallery/assets/email/icons8-apple-logo-60_RVeOgPde9.png","alt":"iCloud Calendar","title":"iCloud Calendar"}})]),_c('a',{attrs:{"href":("https://outlook.office.com/calendar/0/deeplink/compose?body=" + (_vm.booking.meeting_description || '') + "&location=" + (_vm.booking.space.venue.venue_geo.address) + "&startdt=" + (_vm.convertUnixToExternalCalendarDate(
              _vm.booking.slot_start
            )) + "&enddt=" + (_vm.convertUnixToExternalCalendarDate(
              _vm.booking.slot_end
            )) + "&subject=" + (_vm.booking.meeting_name) + "&rru=addevent&path=" + _vm.SITE_URL),"target":"_blank"}},[_c('img',{staticClass:"booking-info__calendar__image",attrs:{"src":"https://media.wemeet.nl/gallery/assets/email/icons8-outlook-calendar-48_BgHTuXYW7.png","alt":"Microsoft Outlook Calendar","title":"Microsoft Outlook Calendar"}})])]),(_vm.$mq && _vm.$mq.phone)?_c('ListItem',{staticClass:"booking-info__timeslots",scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.timeslots)+" ")]},proxy:true}],null,false,2814142813)}):_vm._e()],1),(
          _vm.booking.space &&
          _vm.booking.space.space_images &&
          _vm.booking.space.space_images[0]
        )?_c('div',{staticClass:"booking-info__image"},[_c('img',{attrs:{"src":_vm.bookedSpaceImage,"srcset":(_vm.bookedSpaceImage + "?tr:n-150w 150w," + _vm.bookedSpaceImage + "?tr=w-300w 300w," + _vm.bookedSpaceImage + "?tr=w-500w 600w," + _vm.bookedSpaceImage + "?tr=w-500w 900w," + _vm.bookedSpaceImage + "?tr=w-500w 1200w"),"alt":"Booked space image"}})]):_vm._e(),(_vm.booking.origin)?[_c('div',{staticClass:"booking-info__buttons"},_vm._l((_vm.tabs),function(tab,tabIndex){return _c('WMButton',{key:tabIndex,class:['accent', { active: _vm.currentTab === tabIndex }],on:{"click":function($event){_vm.currentTab = tabIndex}}},[_c('div',{staticClass:"button-booking-status"},[_vm._v(" "+_vm._s(tabIndex === 0 ? _vm.$t('booking.editable.buttons.original') : _vm.$t('booking.editable.buttons.updates'))+" ")]),_c('div',{staticClass:"button-booking-created"},[_vm._v(" "+_vm._s(("Created " + (tab.created) + " "))+" ")])])}),1)]:_vm._e(),(_vm.$mq && !_vm.$mq.phone)?_c('BookingDetailsSummary',{staticClass:"booking-info__configurations",attrs:{"booking":_vm.booking,"tab":_vm.currentTab}}):_vm._e()],2):_vm._e(),_c('section',{staticClass:"booking-bar"},[_c('Status',{attrs:{"tab":_vm.currentTab,"booking":_vm.booking}}),(_vm.timeline && _vm.timeline.EXPIRED && !_vm.roles.VENDOR)?_c('BookingExpired',{attrs:{"booking":_vm.relevantBooking}}):_vm._e(),_c('Access',{attrs:{"role":_vm.UserRoleState.Booker}},[(
            _vm.timeline && _vm.timeline.PAY && !_vm.relevantBooking.is_afterpay_requested
          )?_c('BookingPay',{attrs:{"booking":_vm.relevantBooking},on:{"moreTimeToPayRequested":_vm.onMoreTimeToPayRequested}}):_vm._e()],1),_c('Access',{attrs:{"role":_vm.UserRoleState.Vendor}},[(
            _vm.timeline &&
            (_vm.timeline.PAY || _vm.timeline.OVERDUE_PAYMENT) &&
            _vm.relevantBooking.is_afterpay_requested
          )?_c('BookingPay',{attrs:{"booking":_vm.relevantBooking},on:{"moreTimeToPayRequested":_vm.onMoreTimeToPayRequested}}):_vm._e()],1),_c('Access',{attrs:{"role":_vm.UserRoleState.Vendor}},[(
            _vm.timeline &&
            _vm.timeline.BOOKING_REQUEST_VENDOR_VIEW &&
            _vm.currentTab !== 0
          )?_c('BookingDecision',{attrs:{"booking":_vm.booking},on:{"approved":function($event){return _vm.refetchBooking('approved')},"declined":function($event){return _vm.refetchBooking('declined')}}}):_vm._e()],1),(_vm.booking.cancellation_reason)?_c('BookingCancelReason',{attrs:{"cancellation-reason":_vm.booking.cancellation_reason}}):_vm._e(),_c('Access',{attrs:{"role":_vm.UserRoleState.Vendor}},[(_vm.booking.space && _vm.booking.space.venue)?_c('UserDetails',{attrs:{"full":_vm.timeline && _vm.timeline.USER_INFO,"user":_vm.userInfo,"booking":_vm.booking,"is-vendor-view":true}}):_vm._e()],1),_c('Access',{attrs:{"role":_vm.UserRoleState.Booker}},[(_vm.booking.space && _vm.booking.space.venue)?_c('UserDetails',{attrs:{"full":_vm.timeline && _vm.timeline.USER_INFO,"user":_vm.userInfo,"booking":_vm.relevantBooking}}):_vm._e()],1),(_vm.timeline)?_c('BookingTimeline',{attrs:{"timeline":_vm.timeline}}):_vm._e(),_c('ul',[(
            _vm.roles.BOOKER &&
            _vm.booking.origin &&
            _vm.booking.order.order_state === _vm.OrderStateType.HostApproval &&
            _vm.currentTab !== 0
          )?[(
              _vm.timeline &&
              (_vm.timeline.CUSTOMER_CANCEL_WITH_REFUND ||
                _vm.timeline.CUSTOMER_CANCEL_WITHOUT_REFUND ||
                _vm.timeline.VENDOR_CANCEL ||
                _vm.timeline.VENDOR_CANCEL_WITH_REFUND ||
                _vm.timeline.VENDOR_POST_PAYMENT_INVOICING)
            )?_c('BookingCancellation',{staticClass:"cancel-booking-changes-button",attrs:{"booking":_vm.booking,"cancel-mode":2}}):_vm._e(),(
              _vm.timeline &&
              (_vm.timeline.CUSTOMER_CANCEL_WITH_REFUND ||
                _vm.timeline.CUSTOMER_CANCEL_WITHOUT_REFUND ||
                _vm.timeline.VENDOR_CANCEL ||
                _vm.timeline.VENDOR_CANCEL_WITH_REFUND ||
                _vm.timeline.VENDOR_POST_PAYMENT_INVOICING)
            )?_c('BookingCancellation',{staticClass:"cancel-entire-booking-button",attrs:{"booking":_vm.booking,"cancel-mode":1}}):_vm._e()]:[(
              _vm.timeline &&
              (_vm.timeline.CUSTOMER_CANCEL_WITH_REFUND ||
                _vm.timeline.CUSTOMER_CANCEL_WITHOUT_REFUND ||
                _vm.timeline.VENDOR_CANCEL ||
                _vm.timeline.VENDOR_CANCEL_WITH_REFUND ||
                _vm.timeline.VENDOR_POST_PAYMENT_INVOICING)
            )?_c('BookingCancellation',{attrs:{"booking":_vm.relevantBooking,"cancel-mode":0},on:{"cancelled":_vm.refetchBooking}}):_vm._e()],(
            _vm.roles.BOOKER &&
            _vm.timeline &&
            !_vm.booking.space.is_prospect &&
            _vm.booking.can_be_edited &&
            (_vm.timeline.CUSTOMER_CANCEL_WITH_REFUND ||
              _vm.timeline.CUSTOMER_CANCEL_WITHOUT_REFUND ||
              _vm.timeline.VENDOR_CANCEL ||
              _vm.timeline.VENDOR_CANCEL_WITH_REFUND ||
              _vm.timeline.VENDOR_POST_PAYMENT_INVOICING)
          )?_c('ListItem',{staticClass:"edit-booking-button",attrs:{"icon-class":"fa-solid fa-pen-to-square fa-sm","title":_vm.$t('booking.details.edit.edit_booking')},on:{"click":_vm.onBookingEditClick}}):_vm._e(),(_vm.timeline.FINANCE)?_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath(
              ((_vm.BookingRoutes.BOOKINGS) + "/" + (_vm.relevantBooking.booking_id) + "/" + (_vm.FinanceRoutes.FINANCE))
            )}},[_c('ListItem',{attrs:{"icon-class":"fa-solid fa-money-bill-transfer fa-sm","title":_vm.$t('finance.title')}})],1):_vm._e()],2),_c('BookingHistory',{attrs:{"booking":_vm.relevantBooking}})],1),(_vm.$mq && _vm.$mq.phone)?_c('Collapse',{attrs:{"title":_vm.$t('booking_summary.collapse.title').toString(),"heading":_vm.$t('booking_summary.collapse.heading').toString()}},[(_vm.booking.origin)?[_c('div',{staticClass:"booking-info__buttons"},_vm._l((_vm.tabs),function(tab,tabIndex){return _c('WMButton',{key:tabIndex,class:['accent', { active: _vm.currentTab === tabIndex }],on:{"click":function($event){_vm.currentTab = tabIndex}}},[_c('div',{staticClass:"button-booking-status"},[_vm._v(" "+_vm._s(tabIndex === 0 ? _vm.$t('booking.editable.buttons.original') : _vm.$t('booking.editable.buttons.updates'))+" ")]),_c('div',{staticClass:"button-booking-created"},[_vm._v(" "+_vm._s(("Created " + (tab.created) + " "))+" ")])])}),1)]:_vm._e(),_c('BookingDetailsSummary',{staticClass:"booking-info__configurations",attrs:{"booking":_vm.relevantBooking}})],2):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }