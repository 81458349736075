var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.booking)?_c('ul',{staticClass:"booking-details-summary"},[_c('ListItem',{class:[
      'item-details',
      {
        changed:
          _vm.booking.origin &&
          _vm.tab === 1 &&
          (_vm.booking.meeting_name !== _vm.booking.origin.meeting_name ||
            _vm.booking.meeting_description !==
              _vm.booking.origin.meeting_description)
      }
    ],attrs:{"title":_vm.relevantBooking.meeting_name,"description":_vm.relevantBooking.meeting_description}}),(_vm.relevantBooking.order.order_meta.afterpay_description)?_c('ListItem',{class:[
      'item-details',
      {
        changed:
          _vm.booking.origin &&
          _vm.tab === 1 &&
          _vm.booking.order.order_meta.afterpay_description !==
            _vm.booking.origin.order.order_meta.afterpay_description
      }
    ],attrs:{"title":_vm.$t('booking.post_payment.comment'),"description":_vm.relevantBooking.order.order_meta.afterpay_description}}):_vm._e(),_c('ListItem',{staticClass:"booking-details-summary__options",class:[
      'item-details',
      {
        changed:
          _vm.booking.origin &&
          _vm.tab === 1 &&
          (_vm.booking.slot_start !== _vm.booking.origin.slot_start ||
            _vm.booking.slot_end !== _vm.booking.origin.slot_end)
      }
    ],staticStyle:{"padding-top":"13px","padding-bottom":"13px"},attrs:{"title":_vm.meetingTime,"right-label":_vm._f("currency")(_vm.options.space.order_item_total)}}),_c('ListItem',{staticClass:"booking-details-summary__options",class:[
      'item-details',
      {
        changed:
          _vm.booking.origin &&
          _vm.tab === 1 &&
          _vm.booking.attendees !== _vm.booking.origin.attendees
      }
    ],staticStyle:{"padding-top":"13px","padding-bottom":"13px"},attrs:{"title":_vm.$t('customer.space.attendees.guests'),"right-label":_vm.relevantBooking.attendees.toString()}}),(_vm.options.configuration)?_c('ListItem',{staticClass:"booking-details-summary__options",class:[
      'item-details',
      {
        changed:
          _vm.booking.origin &&
          _vm.tab === 1 &&
          _vm.optionsOrigin.configuration.configuration_id !==
            _vm.options.configuration.configuration_id
      }
    ],staticStyle:{"padding-top":"13px","padding-bottom":"13px"},attrs:{"right-label":_vm._f("currency")(_vm.options.configuration.order_item_total),"description":_vm.$tc('booking_summary.attendees', _vm.booking.attendees),"title":_vm.$t(
        ("booking_summary.configurations." + (_vm.options.configuration.configuration_type.toLowerCase()) + ".title")
      )}}):_vm._e(),_vm._l((_vm.options.facilities),function(services,key){return _c('ListDropdown',{key:key,scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ListItem',{staticClass:"booking-details-summary__services",class:[
          'item-details',
          {
            changed:
              _vm.booking.origin &&
              _vm.tab === 1 &&
              !_vm.options.facilities[key].every(function (el, i) {
                if (key in _vm.optionsOrigin.facilities) {
                  return _vm.isEqual(el, _vm.optionsOrigin.facilities[key][i]);
                } else {
                  return false;
                }
              })
          }
        ],attrs:{"right-label":_vm._f("currency")(Object.values(services).reduce(
            function (acc, item) { return item.order_item_total + acc; },
            0
          )),"title":_vm.user && key
            ? ("" + (_vm.$tc(
                ("booking_summary." + (key.toLowerCase()) + "." + (_vm.user.account_state.toLowerCase()) + "_title"),
                Object.keys(services).length
              )))
            : '',"expandable":""}})]},proxy:true},{key:"items",fn:function(){return _vm._l((services),function(item){return _c('ListItem',{key:item.reference_id,staticClass:"booking-details-summary__options",class:[
          'item-details',
          {
            changed:
              _vm.booking.origin && _vm.tab === 1 && _vm.checkServiceUpdate(key, item)
          }
        ],attrs:{"right-label":_vm._f("currency")(item.order_item_total),"title":_vm.$tc(
            ("booking_summary." + (item.service_type.toLowerCase()) + "." + (item.service_name) + ".title"),
            item.quantity
          )}})})},proxy:true}],null,true)})}),(_vm.relevantBooking.is_discount_applied)?_c('div',{staticClass:"booking-details-summary__vendor-prices discount-list-item",class:[
      'item-details',
      {
        changed:
          _vm.booking.origin &&
          _vm.tab === 1 &&
          _vm.booking.origin.is_discount_applied !== _vm.booking.is_discount_applied
      }
    ]},[_c('div',{staticClass:"booking-details-summary__price"},[_c('p',[(
            _vm.relevantBooking.booking_meta.coupon &&
            _vm.relevantBooking.booking_meta.coupon.coupon_id
          )?_c('strong',[_vm._v(_vm._s(_vm.$tc( 'customer.space.book_preview.discount_level', _vm.relevantBooking.booking_meta.coupon.discount_percentage ))+" ")]):_c('strong',[_vm._v(_vm._s(_vm.$tc( 'customer.space.book_preview.discount_level', _vm.relevantBooking.space.discount_percentage )))])]),_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.relevantBooking.order.discount_total)))])])]):_vm._e(),(
      _vm.relevantBooking.booking_meta.coupon &&
      _vm.relevantBooking.booking_meta.coupon.coupon_id
    )?_c('ul',{staticClass:"booking-details-summary__options"},[_c('ListItem',{staticClass:"booking-details-summary__options",staticStyle:{"padding-bottom":"4px"},attrs:{"right-label":_vm.relevantBooking.booking_meta.coupon.coupon_code,"title":_vm.$t('coupon.coupon_input_placeholder').toString()}})],1):_vm._e(),_c('Access',{attrs:{"role":_vm.UserRoleState.Booker}},[(
        _vm.relevantBooking.order.total_paid &&
        !_vm.relevantBooking.is_afterpay_requested
      )?_c('ListDropdown',{staticClass:"booking-details-summary__prices",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ListItem',{staticClass:"booking-details-summary__primary",attrs:{"title":_vm.$t('booking_summary.summary.paid.title'),"right-label":_vm._f("currency")(_vm.relevantBooking.order.total_paid),"expandable":""}})]},proxy:true},{key:"items",fn:function(){return [_c('div',{staticClass:"booking-details-summary__price"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.price.title')))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.price.description')))])]),_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.relevantBooking.order.order_total_vat_excl)))])]),_c('div',{staticClass:"booking-details-summary__price"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.total.title')))]),_c('br'),_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._v(" "+_vm._s(_vm.$t('booking_summary.summary.total.description'))+" "),_c('VATBreakdown',{staticStyle:{"margin-left":"10px"},attrs:{"vat9":_vm.bookingSummary.vat_9,"vat21":_vm.bookingSummary.vat_21}})],1)]),_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.relevantBooking.order.order_total)))])])]},proxy:true}],null,false,3932440656)}):_c('ul',{staticClass:"booking-details-summary__prices"},[_c('div',{staticClass:"booking-details-summary__price",class:[
          'item-details',
          {
            changed:
              _vm.booking.origin &&
              _vm.tab === 1 &&
              _vm.booking.order.order_total_vat_excl !==
                _vm.booking.origin.order.order_total_vat_excl
          }
        ]},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.price.title')))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.price.description')))])]),_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.relevantBooking.order.order_total_vat_excl)))])]),_c('div',{staticClass:"booking-details-summary__price",class:[
          'item-details',
          {
            changed:
              _vm.booking.origin &&
              _vm.tab === 1 &&
              _vm.booking.order.order_total !== _vm.booking.origin.order.order_total
          }
        ]},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.total.title')))]),_c('br'),_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._v(" "+_vm._s(_vm.$t('booking_summary.summary.total.description'))+" "),_c('VATBreakdown',{staticStyle:{"margin-left":"10px"},attrs:{"vat9":_vm.bookingSummary.vat_9,"vat21":_vm.bookingSummary.vat_21}})],1)]),_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.relevantBooking.order.order_total)))])])])],1),_c('Access',{attrs:{"role":_vm.UserRoleState.Vendor}},[_c('section',[(_vm.relevantBooking.order.total_paid)?_c('ListDropdown',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ListItem',{staticClass:"booking-details-summary__primary",attrs:{"title":_vm.$t('booking_summary.summary.paid.title'),"right-label":_vm._f("currency")(_vm.relevantBooking.order.total_paid),"expandable":""}})]},proxy:true},{key:"items",fn:function(){return [_c('div',{staticClass:"booking-details-summary__price",class:[
              'item-details',
              {
                changed:
                  _vm.booking.origin &&
                  _vm.tab === 1 &&
                  _vm.booking.order.service_fee !==
                    _vm.booking.origin.order.service_fee
              }
            ]},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.vendor_service.title')))]),_c('br'),(_vm.relevantBooking.is_afterpay_requested)?_c('span',[_vm._v(_vm._s(_vm.$t( 'booking_summary.summary.vendor_service.afterpay_description', { service_fee_rate: _vm.relevantBooking.space.service_fee_rate } ))+" ")]):_vm._e(),(
                  !_vm.relevantBooking.is_afterpay_requested &&
                  !_vm.booking.booking_meta.is_prospect_booking
                )?_c('span',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.vendor_service.description', { service_fee_rate: _vm.relevantBooking.space.service_fee_rate })))]):_vm._e()]),_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.relevantBooking.order.service_fee_total)))])]),_c('div',{staticClass:"booking-details-summary__price",class:[
              'item-details',
              {
                changed:
                  _vm.booking.origin &&
                  _vm.tab === 1 &&
                  _vm.booking.order.order_total !==
                    _vm.booking.origin.order.order_total
              }
            ]},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.vendor_customer_total.title')))])]),_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.relevantBooking.order.payout_total)))])])]},proxy:true}],null,false,283243632)}):_c('ul',{staticClass:"booking-details-summary__vendor-prices"},[_c('div',{staticClass:"booking-details-summary__price",class:[
            'item-details',
            {
              changed:
                _vm.booking.origin &&
                _vm.tab === 1 &&
                _vm.booking.order.service_fee !== _vm.booking.origin.order.service_fee
            }
          ]},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.vendor_service.title')))]),_c('br'),(_vm.relevantBooking.is_afterpay_requested)?_c('span',[_vm._v(_vm._s(_vm.$t( 'booking_summary.summary.vendor_service.afterpay_description', { service_fee_rate: _vm.relevantBooking.space.service_fee_rate } ))+" ")]):_vm._e(),(
                !_vm.relevantBooking.booking_meta.is_prospect_booking &&
                !_vm.relevantBooking.is_afterpay_requested
              )?_c('span',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.vendor_service.description', { service_fee_rate: _vm.relevantBooking.space.service_fee_rate })))]):_vm._e()]),_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.relevantBooking.order.service_fee_total)))])]),_c('div',{staticClass:"booking-details-summary__price",class:[
            'item-details',
            {
              changed:
                _vm.booking.origin &&
                _vm.tab === 1 &&
                _vm.booking.order.order_total !== _vm.booking.origin.order.order_total
            }
          ]},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.vendor_customer_total.title')))])]),_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.relevantBooking.order.order_total - _vm.relevantBooking.order.service_fee_total)))+" ")])])])],1)]),(_vm.relevantBooking.order.refunded_total)?_c('div',{staticClass:"booking-details-summary__refunded",class:[
      'item-details',
      {
        changed:
          _vm.booking.origin &&
          _vm.tab === 1 &&
          _vm.booking.order.refunded_total !== _vm.booking.origin.order.refunded_total
      }
    ]},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.refunded.title')))]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.$t('booking_summary.summary.refunded.description'))+" "),_c('i',{staticClass:"fa-solid fa-circle-question fa-sm"})])]),_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.relevantBooking.order.refunded_total)))])]):_vm._e(),_c('Access',{attrs:{"role":_vm.UserRoleState.Booker}},[(
        _vm.booking.origin &&
        _vm.booking.order.order_state === _vm.OrderStateType.HostApproval &&
        !_vm.booking.space.is_prospect &&
        _vm.booking.order.order_total > 0 &&
        _vm.booking.origin.booking_status === _vm.BookingStatus.Paid
      )?_c('div',{staticClass:"booking-details-summary-price-change-info"},[_c('i',{staticClass:"fa-solid fa-circle-exclamation fa-lg"}),_c('p',[_vm._v(" "+_vm._s(_vm.$tc( ("booking_summary.refund_info." + (_vm.getBookingRefund(_vm.booking).label)), _vm.getBookingRefund(_vm.booking).amount ))+" ")])]):_vm._e()])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }