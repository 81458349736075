import { defineComponent, ref } from '@vue/composition-api';
import BookingTimelineCard from '@/Booking/components/BookingTimelineCard.vue';
import { useRecreateBooking } from '@/shared/composables/useRecreateBooking';
import { BookingRoutes } from '@/Booking/booking.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        BookingTimelineCard
    },
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const hasBookingRecreationError = ref(false);
        const { recreateBooking } = useRecreateBooking();
        const makeNewBooking = async () => {
            try {
                const response = await recreateBooking(props.booking);
                globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${response?.mutationViewerApiKey?.postApiBookingSpace?.bookingId}`));
            }
            catch (error) {
                hasBookingRecreationError.value = true;
            }
        };
        return {
            hasBookingRecreationError,
            makeNewBooking
        };
    }
});
