import { defineComponent } from '@vue/composition-api';
import { BookingChatDocument } from '@/generated-types/graphql.types';
import { apolloClient } from '@/main';
import { ChatRoutes } from '@/Chat/chat.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import ListItem from '@/uikit/ListItem.vue';
export default defineComponent({
    components: {
        ListItem
    },
    props: {
        user: {
            type: Object,
            default: null
        },
        booking: {
            type: Object,
            default: null
        },
        full: Boolean,
        isVendorView: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const createChat = async () => {
            const { data, errors } = await apolloClient.query({
                query: BookingChatDocument,
                variables: { id: props.booking.booking_id },
                fetchPolicy: 'no-cache'
            });
            if (!errors && data?.get_chat) {
                globalThis.$router.push(useGetLocalizedPath(`${ChatRoutes.CHAT}/${data.get_chat.channel_id}`));
            }
        };
        return {
            createChat
        };
    }
});
