import { defineComponent, computed, watch, ref } from '@vue/composition-api';
import { useGetBookingQuery, BookingStatus, BookingType, UserRoleState, OrderItemType, OrderStateType, SystemServiceType } from '@/generated-types/graphql.types';
import Label from '@/uikit/Label.vue';
import UserDetails from '@/Booking/components/UserDetails.vue';
import BookingDetailsSummary from '@/Booking/BookingDetails/BookingDetailsSummary.vue';
import BookingCancellation from '@/Booking/components/BookingCancellation.vue';
import BookingTimeline from '@/Booking/components/BookingTimeline.vue';
import BookingPay from '@/Booking/components/BookingPay.vue';
import BookingDecision from '@/Booking/components/BookingDecision.vue';
import BookingCancelReason from '@/Booking/components/BookingCancelReason.vue';
import Access from '@/components/Access.vue';
import useUserRoles from '@/shared/composables/useUserRoles';
import { getBookingTimeline } from '@/Booking/utils/bookings';
import { unixFormatted, useBookingTimeSlots, 
// isDST,
convertUnixToExternalCalendarDate } from '@/util/dates';
import BookingExpired from '@/Booking/components/BookingExpired.vue';
import Status from '@/Booking/components/BookingStatus.vue';
import Collapse from '@/uikit/Collapse.vue';
import BookingPaymentStatus from '@/Booking/components/BookingPaymentStatus.vue';
import ListItem from '@/uikit/ListItem.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { BookingRoutes } from '@/Booking/booking.const';
import { SITE_URL } from '@/App.vue';
import { SpaceRoutes } from '@/Space/space.const';
import { VenueRoutes } from '@/Venue/venue.const';
import { FinanceRoutes } from '@/Finance/finance.const';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import BookingHistory from '@/Booking/components/BookingHistory.vue';
const bookingStatusImages = {
    DIRECT: 'bolt-lightning',
    REQUEST: 'circle-question'
};
export default defineComponent({
    components: {
        ListItem,
        Label,
        UserDetails,
        BookingTimeline,
        BookingPay,
        Access,
        BookingDecision,
        BookingExpired,
        BookingDetailsSummary,
        BookingCancelReason,
        BookingCancellation,
        Collapse,
        Status,
        BookingPaymentStatus,
        BookingHistory
    },
    setup(_, context) {
        const { root } = context;
        const bookingId = ref(+root.$route.params.bookingId);
        const booking = ref(null);
        const { refetch, onResult, error } = useGetBookingQuery({
            id: bookingId.value
        }, {
            fetchPolicy: 'no-cache'
        });
        const currentTab = ref(1);
        const roles = useUserRoles();
        const bookedSpaceImage = computed(() => {
            return booking?.value?.space?.space_images[0]?.image_url;
        });
        const relevantBooking = computed(() => {
            return currentTab.value === 1 ? booking.value : booking.value.origin;
        });
        const tabs = computed(() => {
            return [
                {
                    created: unixFormatted(booking.value.origin.ts_created, 'D MMM')
                },
                {
                    created: unixFormatted(booking.value.ts_created, 'D MMM')
                }
            ];
        });
        const timeslots = computed(() => useBookingTimeSlots(relevantBooking.value).value);
        const timeline = computed(() => {
            return getBookingTimeline(relevantBooking.value, roles.value?.VENDOR);
        });
        const errorMessage = computed(() => {
            if (error.value)
                globalThis.$router.push({ name: ROUTE_LIST_NAMES.ERRORS.NOT_FOUND });
            return error?.value?.message;
        });
        const refetchBooking = (mode = '') => {
            if (mode === 'declined' && booking.value.origin) {
                // redirect to the original booking if the change request was declined
                globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${booking.value.origin.booking_id}`));
                return;
            }
            refetch({
                id: bookingId.value
            });
        };
        const userInfo = computed(() => {
            return roles.value.VENDOR
                ? {
                    givenName: booking?.value?.order?.booker.first_name.concat(' ', booking?.value?.order?.booker.last_name),
                    phone: booking?.value?.order?.booker.phone,
                    email: booking?.value?.order?.booker.email
                }
                : {
                    givenName: booking?.value?.order?.vendor.first_name.concat(' ', booking?.value?.order?.vendor.last_name),
                    phone: booking?.value?.order?.vendor.phone,
                    email: booking?.value?.order?.vendor.email
                };
        });
        const onBookingEditClick = () => {
            sessionStorage.setItem('bookingId', booking.value.booking_id);
            // const _isDST = isDST(booking.value.slot_start);
            // const query = {
            //   slotEnd: _isDST
            //     ? booking.value.slot_end + 3600
            //     : booking.value.slot_end,
            //   slotStart: _isDST
            //     ? booking.value.slot_start + 3600
            //     : booking.value.slot_start,
            const query = {
                slotEnd: booking.value.slot_end + 3600,
                slotStart: booking.value.slot_start + 3600,
                attendees: booking.value.attendees,
                isDiscountRequested: booking.value.is_discount_applied,
                configuration: booking.value.order.order_items.find(service => service.order_item_type === OrderItemType.Configuration).reference_id,
                facilities: booking.value.order.order_items
                    .filter(item => {
                    return (item.order_item_type === OrderItemType.Service &&
                        item.service_type === SystemServiceType.Facility);
                })
                    .map(item => `${item.service_name}+${item.reference_id}+${item.quantity}+${item.is_mandatory}+${item.reference_id}`),
                caterings: booking.value.order.order_items
                    .filter(item => {
                    return (item.order_item_type === OrderItemType.Service &&
                        item.service_type === SystemServiceType.Catering);
                })
                    .map(item => `${item.service_name}+${item.reference_id}+${item.quantity}+${item.is_mandatory}+${item.reference_id}`),
                editable: true,
                couponDiscount: booking.value.booking_meta?.coupon?.discount_percentage,
                couponId: booking.value.booking_meta?.coupon?.coupon_id
            };
            globalThis.$router.push({
                path: useGetLocalizedPath(`${SpaceRoutes.SPACE}${booking.value.space.space_url}`),
                query
            });
            globalThis.$gtm.trackEvent({
                event: 'booking_edit',
                category: 'bookings',
                action: 'booking_edit',
                label: 'Booking edit clicked',
                value: booking?.value?.booking_id,
                noninteraction: false
            });
        };
        onResult(result => {
            if (!result || !result.data) {
                globalThis.$router.push({ name: ROUTE_LIST_NAMES.ERRORS.NOT_FOUND });
            }
            booking.value = result?.data.specific_booking;
            if (booking.value?.replacement?.booking_id) {
                // if there is an active change request, redirect user to the change request
                globalThis.$router.replace({
                    path: `/${BookingRoutes.BOOKINGS}/${booking.value.replacement.booking_id}`
                });
                bookingId.value = booking.value.replacement.booking_id;
                refetchBooking();
            }
        });
        watch(() => root.$route.params, () => {
            bookingId.value = +root.$route.params.bookingId;
            refetchBooking();
        }, { immediate: true });
        /**
         * Re-fetch booking details when more payment time is requested by the user
         */
        const onMoreTimeToPayRequested = () => {
            refetchBooking();
        };
        const handleChangedStatus = () => {
            refetchBooking();
        };
        return {
            bookedSpaceImage,
            booking,
            bookingStatusImages,
            currentTab,
            errorMessage,
            relevantBooking,
            roles,
            tabs,
            timeline,
            timeslots,
            userInfo,
            BookingRoutes,
            BookingStatus,
            BookingType: BookingType,
            FinanceRoutes,
            OrderStateType,
            SITE_URL,
            SpaceRoutes,
            UserRoleState,
            VenueRoutes,
            convertUnixToExternalCalendarDate,
            handleChangedStatus,
            onBookingEditClick,
            onMoreTimeToPayRequested,
            refetchBooking,
            timezone: globalThis.$timezone,
            useGetLocalizedPath
        };
    }
});
