import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { UserRoleState } from '@/generated-types/graphql.types';
import { isEqual } from 'lodash';
import { unixFormatted } from '@/util/dates';
import useUserRoles from '@/shared/composables/useUserRoles';
import Access from '@/components/Access.vue';
const statusList = {
    paid: {
        type: ['PAID', 'PAID', 'PAID'],
        vendorOnly: false
    },
    payment_pending: {
        type: ['PENDING', 'PENDING', 'PAYMENT_PENDING'],
        vendorOnly: false,
        isPostPayment: false
    },
    post_payment_confirmed: {
        type: ['PENDING', 'PENDING', 'PAYMENT_PENDING'],
        vendorOnly: false,
        isPostPayment: true
    },
    paid_by_vendor: {
        type: ['PASSED', 'PASSED', 'PAID'],
        vendorOnly: true
    },
    passed_and_waits_for_payout: {
        type: ['PASSED', 'PAID', 'PAID'],
        vendorOnly: false
    },
    cancelled_by_host: {
        type: ['CANCELLED', 'CANCELLED', 'CLOSED_BY_HOST'],
        vendorOnly: false
    },
    declined_by_host: {
        type: ['DECLINED', 'DECLINED', 'CLOSED_BY_HOST'],
        vendorOnly: false
    },
    expired: {
        type: ['EXPIRED', 'EXPIRED', 'EXPIRED'],
        vendorOnly: false
    },
    awaits_vendor_payment: {
        type: ['PASSED', 'PASSED', 'PAYMENT_PENDING'],
        vendorOnly: false
    },
    payout_completed: {
        type: ['PASSED', 'PAID', 'PAYOUT_COMPLETED'],
        vendorOnly: true
    },
    cancelled_by_host_with_full_refund: {
        type: ['CANCELLED', 'REFUNDED', 'CLOSED_BY_HOST'],
        vendorOnly: false
    },
    cancelled_by_guest_with_full_refund: {
        type: ['CANCELLED', 'REFUNDED', 'CLOSED_BY_CUSTOMER'],
        vendorOnly: false
    },
    cancelled_by_guest_without_refund: {
        type: ['CANCELLED', 'PAID', 'CLOSED_BY_CUSTOMER'],
        vendorOnly: false
    },
    cancelled_by_guest: {
        type: ['CANCELLED', 'CANCELLED', 'CLOSED_BY_CUSTOMER'],
        vendorOnly: false
    },
    awaits_payout: {
        type: ['PASSED', 'PAID', 'PAYOUT_PENDING'],
        vendorOnly: true
    },
    payment_failed: {
        type: ['PENDING', 'PENDING', 'PAYMENT_FAILED'],
        vendorOnly: false
    },
    payment_failed_vendors: {
        type: ['PASSED', 'PASSED', 'PAYMENT_FAILED'],
        vendorOnly: true
    },
    awaits_confirmation: {
        type: ['PENDING', 'PENDING', 'HOST_APPROVAL'],
        vendorOnly: false
    }
};
export default defineComponent({
    components: { Access },
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const bookingHistory = ref([]);
        const roles = useUserRoles();
        watch(() => props.booking, () => {
            bookingHistory.value = props.booking.booking_history
                .map(el => {
                const status = Object.values(el).slice(0, 3);
                let name = Object.keys(statusList).find(key => isEqual(statusList[key].type, status) &&
                    (statusList[key].isPostPayment === undefined ||
                        statusList[key].isPostPayment ===
                            props.booking.is_afterpay_requested));
                if (name) {
                    return {
                        name,
                        created: unixFormatted(el.ts_created, 'D MMMM YYYY HH:mm'),
                        vendorOnly: statusList[name].vendorOnly
                    };
                }
            })
                .filter(el => el);
        }, { immediate: true, deep: true });
        return {
            bookingHistory,
            userType: computed(() => +roles.value.VENDOR + 1),
            UserRoleState
        };
    }
});
