import ListItem from '@/uikit/ListItem.vue';
import ShareToPay from '@/Booking/components/ShareToPay.vue';
import { computed, defineComponent } from '@vue/composition-api';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import { BookingStatus, useRequestPayTimeMutation, useRequestPaymentLinkMutation } from '@/generated-types/graphql.types';
import { useRecreateBooking } from '@/shared/composables/useRecreateBooking';
import { BookingRoutes } from '@/Booking/booking.const';
import ListDropdown from '@/uikit/ListDropdown.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        ListDropdown,
        ListItem,
        ShareToPay
    },
    props: {
        booking: {
            type: Object,
            default: null
        },
        expired: {
            type: Boolean,
            default: false
        },
        tab: {
            type: Number,
            default: 1
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const { mutate, onDone } = useRequestPayTimeMutation({});
        const { mutate: requestPaymentLinkMutate } = useRequestPaymentLinkMutation({});
        const relevantBooking = computed(() => {
            return props.tab === 1 ? props.booking : props.booking.origin;
        });
        const requestTime = () => {
            mutate({ id: relevantBooking.value.booking_id }, {}).then(() => emit('moreTimeToPayRequested'));
        };
        const extraPaymentTotal = computed(() => {
            return (props.booking?.order?.order_total -
                props.booking?.origin?.order.order_total).toFixed(2);
        });
        const extraPaymentStatus = computed(() => {
            return (props.booking.origin &&
                props.booking.origin.booking_status === BookingStatus.Paid);
        });
        const { recreateBooking } = useRecreateBooking();
        const canBookingBeExtended = computed(() => {
            return relevantBooking.value.reservation_time >= 4320;
        });
        const pay = async () => {
            let bookingId = relevantBooking.value.booking_id;
            if (props.expired && !relevantBooking.value.is_afterpay_requested) {
                try {
                    const response = await recreateBooking(props.booking);
                    bookingId =
                        response?.mutationViewerApiKey?.postApiBookingSpace.bookingId;
                    globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${bookingId}`));
                }
                catch (error) {
                    console.error('Error recreating booking', error);
                }
            }
            requestPaymentLink(bookingId);
        };
        const getPathFromUrl = url => url.split('?')[0];
        // https://wemeet.atlassian.net/browse/WM-2505
        // ToDo: Fix code/logic inconsistency. Only new payments use this redirectURL.
        // Failed payments will get one from the backend.
        const requestPaymentLink = (id) => {
            requestPaymentLinkMutate({
                data: {
                    booking_id: id,
                    redirect_url: getPathFromUrl(window.location.href)
                }
            }, {}).then(({ data }) => {
                const { payment_link } = data.create_payment_link;
                window.location.replace(payment_link);
            });
        };
        /**
         * Show toast message when booking payment time has been successfully extended
         */
        onDone(({ data }) => {
            const isExtended = data?.update_booking?.is_reservation_extended;
            const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
            if (isExtended) {
                addToast({
                    text: root.$i18n.t('booking.reservation_extended'),
                    type: 'success',
                    dismissAfter: 10000
                });
            }
        });
        return {
            canBookingBeExtended,
            extraPaymentStatus,
            extraPaymentTotal,
            relevantBooking,
            pay,
            requestTime
        };
    }
});
