import { computed, defineComponent, ref, reactive } from '@vue/composition-api';
import { BookingStatus, SupportChatDocument, useCancelBookingMutation, useIsRefundQuery, UserRoleState, UserEventPermission } from '@/generated-types/graphql.types';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Access from '@/components/Access.vue';
import { currencyFilter } from '@/util/templateFilters';
import { ServerErrors } from '@/util/graphql';
import { useUserDetailsCompleted } from '@/shared/composables/useUserDetailsCompleted';
import { ChatRoutes } from '@/Chat/chat.const';
import { PRE_SAVE_ACTION_TYPES, preSaveAction, resetPreSaveAction } from '@/util/preSaveActions';
import { saveCurrentPath } from '@/util/utils';
import { apolloClient } from '@/main';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { BookingRoutes } from '@/Booking/booking.const';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
export default defineComponent({
    components: {
        Access
    },
    mixins: [validationMixin],
    validations: {
        form: {
            reason: {
                required
            }
        }
    },
    props: {
        booking: {
            type: Object,
            default: () => { }
        },
        cancelMode: {
            type: Number,
            default: 0
        }
    },
    setup(props, context) {
        const { emit, root } = context;
        const form = ref({
            reason: ''
        });
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const hasPermissionToCancelPaidBookings = computed(() => user.value?.event_permissions[UserEventPermission.AllowedToCancelPaidBookingsAsVendor] || false);
        const { performAccountFlowAction } = useUserDetailsCompleted(root);
        const createChat = () => {
            if (performAccountFlowAction.value) {
                preSaveAction({
                    type: PRE_SAVE_ACTION_TYPES.CHAT,
                    space: props.booking.space,
                    chatType: null
                });
                saveCurrentPath(root.$route.fullPath.slice(1));
                performAccountFlowAction.value(UserRoleState.Vendor);
                return;
            }
            // Send event
            globalThis.$gtm.trackEvent({
                event: 'space_chat_opened',
                category: 'consideration',
                action: 'chat_opened',
                label: undefined,
                value: props.booking.space.space_name || '',
                noninteraction: false
            });
            openChat(props.booking.space)();
        };
        const openChat = (space) => {
            resetPreSaveAction();
            return async () => {
                const { data, errors } = await apolloClient.query({
                    query: SupportChatDocument,
                    variables: { id: space.space_id, chatEventName: null },
                    fetchPolicy: 'no-cache'
                });
                if (!errors && data?.get_chat) {
                    globalThis.$router.push(useGetLocalizedPath(`${ChatRoutes.CHAT}/${data.get_chat.channel_id}`));
                }
            };
        };
        const isProspectBooking = computed(() => {
            return props.booking?.booking_meta?.is_prospect_booking || false;
        });
        const { mutate: cancelBookingMutation } = useCancelBookingMutation({});
        const cancelModal = ref(false);
        let cancelRequestModal = ref(false);
        let discardChangesModal = ref(false);
        const errors = reactive({
            reason: ''
        });
        const { result } = useIsRefundQuery({ booking_id: props.booking?.booking_id }, { enabled: !!props.booking?.booking_id });
        const cancelBookingRequest = (form) => {
            if (user.value?.account_state === UserRoleState.Booker &&
                props.cancelMode === 2) {
                discardChangesModal.value = true;
            }
            else if (!hasPermissionToCancelPaidBookings.value &&
                !props.booking.is_afterpay_requested &&
                props.booking.booking_meta?.coupon?.discount_percentage !== 100 &&
                !props.booking.booking_meta?.is_prospect_booking &&
                props.booking.booking_status === BookingStatus.Paid &&
                user.value?.account_state === UserRoleState.Vendor) {
                cancelRequestModal.value = true;
            }
            else {
                form?.$reset();
                cancelModal.value = true;
            }
        };
        const refund = computed(() => {
            if (!+props.booking?.order?.order_total) {
                return {};
            }
            return result.value?.check_refund;
        });
        const handleSubmit = async (reason) => {
            const onlyUpdates = props.cancelMode === 2;
            const bookingToCancelId = props.cancelMode === 1
                ? props.booking?.origin.booking_id
                : props.booking?.booking_id;
            const bookingToRedirectId = props.cancelMode
                ? props.booking?.origin?.booking_id
                : props.booking?.booking_id;
            if (bookingToCancelId) {
                await cancelBookingMutation({ id: bookingToCancelId, reason, onlyUpdates }, {})
                    .then(() => {
                    if (props.cancelMode) {
                        globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${bookingToRedirectId}`));
                    }
                    else {
                        emit('cancelled');
                    }
                })
                    .catch(({ graphQLErrors }) => {
                    if (graphQLErrors) {
                        const graphQLError = ServerErrors.extractGQLError(graphQLErrors);
                        if (graphQLError?.error?.key === 'code_injection') {
                            errors.reason = root.$i18n.t('common.errors.code_injection');
                        }
                        else {
                            console.log(graphQLError);
                        }
                    }
                });
                cancelModal.value = false;
                discardChangesModal.value = false;
            }
        };
        return {
            cancelRequestModal,
            cancelModal,
            discardChangesModal,
            errors,
            form,
            hasPermissionToCancelPaidBookings,
            isProspectBooking,
            refund,
            BookingStatus,
            HelpdeskRoutes,
            UserRoleState,
            cancelBookingRequest,
            createChat,
            currencyFilter,
            handleSubmit,
            useGetLocalizedPath
        };
    }
});
