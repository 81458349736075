import { defineComponent } from '@vue/composition-api';
import BookingTimelineCard from '@/Booking/components/BookingTimelineCard.vue';
export default defineComponent({
    components: {
        BookingTimelineCard
    },
    props: {
        cancellationReason: {
            type: String,
            default: ''
        }
    }
});
