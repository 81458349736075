import { defineComponent, ref } from '@vue/composition-api';
import { useApproveBookingMutation, useRejectBookingMutation } from '@/generated-types/graphql.types';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
export default defineComponent({
    mixins: [validationMixin],
    validations: {
        form: {
            reason: {
                required
            }
        }
    },
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props, { emit }) {
        const form = ref({
            reason: ''
        });
        const { mutate: approveMutation } = useApproveBookingMutation({});
        const { mutate: rejectMutation } = useRejectBookingMutation({});
        const cancelModal = ref(false);
        const prospectDeclineConfirmationModalVisible = ref(false);
        const optedOutAsProspect = ref(false);
        const approve = () => {
            approveMutation({ id: props.booking.booking_id }, {}).then(() => {
                emit('approved');
            });
        };
        const reject = (form) => {
            form?.$reset();
            if (props.booking?.space?.is_prospect) {
                prospectDeclineConfirmationModalVisible.value = true;
                cancelModal.value = false;
            }
            else {
                cancelModal.value = true;
                prospectDeclineConfirmationModalVisible.value = false;
            }
        };
        const handleSubmit = async (reason) => {
            await rejectMutation({
                id: props.booking?.booking_id,
                reason,
                optedOutAsProspect: optedOutAsProspect.value
            }, {}).then(() => {
                emit('declined');
                cancelModal.value = false;
            });
        };
        /**
         * When user clicks on "Decline and opt out from future requests" button.
         * Booking gets declined and the space is marked as "not available" for future requests.
         */
        const onProspectRequestOptOut = () => {
            prospectDeclineConfirmationModalVisible.value = false;
            cancelModal.value = true;
            optedOutAsProspect.value = true;
            return;
        };
        /**
         * When user clicks on "Decline and keep receiving requests" button.
         * Booking gets declined and the space is marked as "available" for future requests.
         */
        const onProspectRequestDecline = () => {
            prospectDeclineConfirmationModalVisible.value = false;
            cancelModal.value = true;
            return;
        };
        return {
            cancelModal,
            form,
            prospectDeclineConfirmationModalVisible,
            approve,
            handleSubmit,
            onProspectRequestDecline,
            onProspectRequestOptOut,
            reject
        };
    }
});
