import { computed, defineComponent } from '@vue/composition-api';
import { UserRoleState, OrderStateType, BookingStatus } from '@/generated-types/graphql.types';
import ListItem from '@/uikit/ListItem.vue';
import Access from '@/components/Access.vue';
import { getBookingOptions, getBookingRefund, getFacilities } from '@/Booking/utils/bookings';
import { useBookingTimeSlots } from '@/util/dates';
import VATBreakdown from '@/Space/components/VATBreakdown.vue';
import { isEqual } from 'lodash';
import ListDropdown from '@/uikit/ListDropdown.vue';
export default defineComponent({
    components: {
        ListItem,
        Access,
        VATBreakdown,
        ListDropdown
    },
    props: {
        booking: {
            type: Object,
            default: null
        },
        tab: {
            type: Number,
            default: 1
        }
    },
    setup(props) {
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const meetingTime = computed(() => {
            return props.tab === 1
                ? useBookingTimeSlots(props.booking).value
                : useBookingTimeSlots(props.booking.origin).value;
        });
        const relevantBooking = computed(() => {
            return props.tab === 1 ? props.booking : props.booking.origin;
        });
        const bookingSummary = computed(() => {
            const vat9items = relevantBooking.value.order?.order_items?.filter(item => item.vat_rate === 9);
            const vat9total = vat9items?.reduce((acc, item) => item.vat_rate === 9
                ? (item.order_item_price * item.vat_rate) / 100 + acc
                : 0, 0) || 0;
            return {
                vat_9: vat9total,
                vat_21: relevantBooking.value.order?.vat_total - vat9total
            };
        });
        const options = computed(() => {
            const bookingOptions = getBookingOptions(relevantBooking.value);
            return {
                space: bookingOptions?.space?.[0],
                configuration: bookingOptions?.configurations?.[0],
                facilities: getFacilities(bookingOptions?.services)
            };
        });
        const optionsOrigin = computed(() => {
            const bookingOptions = getBookingOptions(props.booking.origin);
            return {
                space: bookingOptions?.space?.[0],
                configuration: bookingOptions?.configurations?.[0],
                facilities: getFacilities(bookingOptions?.services)
            };
        });
        const checkServiceUpdate = (type, item) => {
            if (!Object.prototype.hasOwnProperty.call(optionsOrigin.value.facilities, type))
                return true;
            const originItem = optionsOrigin.value.facilities[type].find(el => el.reference_id === item.reference_id);
            if (originItem) {
                return originItem.quantity !== item.quantity;
            }
            return true;
        };
        return {
            bookingSummary,
            meetingTime,
            options,
            optionsOrigin,
            relevantBooking,
            user,
            OrderStateType,
            UserRoleState,
            checkServiceUpdate,
            getBookingRefund
        };
    },
    computed: {
        BookingStatus() {
            return BookingStatus;
        }
    },
    methods: { isEqual }
});
