import { defineComponent, computed } from '@vue/composition-api';
import useUserRoles from '@/shared/composables/useUserRoles';
import { getRelativeTime } from '@/util/dates';
import { TimelineActions } from '@/Booking/booking.const';
import ListItem from '@/uikit/ListItem.vue';
export default defineComponent({
    components: {
        ListItem
    },
    props: {
        timeline: {
            type: Object,
            default: null
        }
    },
    setup(props, context) {
        const { root } = context;
        const roles = useUserRoles();
        const bookingTimeline = computed(() => {
            return Object.entries(props.timeline)?.filter(([key, value]) => value && root.$te(`booking.details.timeline.${key.toLowerCase()}`));
        });
        return {
            userType: computed(() => +roles.value.VENDOR + 1),
            getRelativeTime,
            bookingTimeline,
            TimelineActions
        };
    }
});
