import { useCreateBookingMutation, useUpdateBookingMeetingMutation } from '@/generated-types/graphql.types';
import { generateLegacyBooking } from '@/Booking/utils/bookings';
import { withAuthMutationHook } from '@/util/hooks';
const createBooking = withAuthMutationHook(useCreateBookingMutation);
export const useRecreateBooking = () => {
    const { mutate: createBookingMutation } = createBooking({
        clientId: 'legacy'
    });
    const { mutate: addBookingMeeting } = useUpdateBookingMeetingMutation({});
    const recreateBooking = async (booking) => {
        const newBooking = generateLegacyBooking(booking);
        const { data: createdBooking } = await createBookingMutation({
            input: newBooking
        });
        await addBookingMeeting({
            id: createdBooking?.mutationViewerApiKey?.postApiBookingSpace?.bookingId,
            data: {
                meeting_name: booking.meeting_name || '',
                meeting_description: booking.meeting_description,
                is_afterpay_requested: booking.is_afterpay_requested,
                order_meta: booking.order_meta
            }
        });
        return createdBooking;
    };
    return { recreateBooking };
};
