var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('section',{staticClass:"user-details"},[_c('header',[(_vm.user.givenName)?_c('div',{staticClass:"user-details__given-name"},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc('booking.details.host.title', +!_vm.isVendorView, { user: _vm.user.givenName })))])]):_vm._e(),(_vm.booking && !_vm.isVendorView)?_c('WMButton',{attrs:{"icon-class-name":"fa-solid fa-comment fa-ml","data-gtm":"booking_details_contact_host_button"},on:{"click":_vm.createChat}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.$t('booking.details.host.contact'))+" ")])]):_vm._e()],1),(_vm.full)?_c('ul',{staticClass:"user-details__list"},[(_vm.user.email)?_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.user.email)+" ")]},proxy:true}],null,false,2247277804)}):_vm._e(),(_vm.user.phone)?_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.user.phone)+" ")]},proxy:true}],null,false,3748984572)}):_vm._e(),(
        _vm.booking.is_afterpay_requested &&
        _vm.booking.order.order_meta.organisation_name &&
        _vm.isVendorView
      )?_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.booking.order.order_meta.organisation_name)+" ")]},proxy:true}],null,false,3472396368)}):_vm._e(),(
        _vm.booking.is_afterpay_requested &&
        _vm.booking.order.order_meta.billing_address &&
        _vm.isVendorView
      )?_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.booking.order.order_meta.billing_address)+" ")]},proxy:true}],null,false,823807832)}):_vm._e(),(
        _vm.booking.is_afterpay_requested &&
        _vm.booking.order.order_meta.kvk_number &&
        _vm.isVendorView
      )?_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_vm._v(" KvK: "+_vm._s(_vm.booking.order.order_meta.kvk_number)+" ")]},proxy:true}],null,false,2309039612)}):_vm._e()],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }